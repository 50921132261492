<form [formGroup]="data.productForm" (submit)="submit()">
  <div mat-dialog-title>
    <h2 fxFlex>Deal #{{dealId}} Details <tc-deal-view-incomplete #incomplete [dealViewRaw$]="dealViewRaw$"></tc-deal-view-incomplete></h2>
    <button mat-button type="button" (click)="cancel()">
      Cancel
    </button>
    <button mat-raised-button type="submit" color="primary"
        [matTooltip]="incomplete.incomplete" matTooltipClass="pre-tooltip"
        [disabled]="!!incomplete.incomplete">
      <i class="fa fa-fw"
          [class.fa-save]="!(inProgress$ | async)"
          [class.fa-spin]="inProgress$ | async"
          [class.fa-spinner]="inProgress$ | async"
          ></i> Save
    </button>
  </div>
  <div mat-dialog-content>
    <h5>Product {{productIndex + 1}}</h5>
    <tc-deal-product-form
        [invoices$]="invoices$"
        [dealForm]="data.dealForm"
        [productForm]="data.productForm"
        ></tc-deal-product-form>
  </div>
</form>
