import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DealViewRaw } from '@tradecafe/types/core';
import { DeepReadonly } from '@tradecafe/types/utils';
import { BehaviorSubject } from 'rxjs';
import { OperationsApiService } from 'src/api/operations';
import { ToasterService } from 'src/shared/toaster/toaster.service';

export interface AdditionalCostDialogOptions {
  dealId: string;
  brokerage?: true;
  dealViewRaw?: DeepReadonly<DealViewRaw>
}

@Component({
  selector: 'tc-additional-cost-dialog',
  templateUrl: './additional-cost-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalCostDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: AdditionalCostDialogOptions,
    private dialogRef: MatDialogRef<AdditionalCostDialogComponent, void>,
    private OperationsApi: OperationsApiService,
    private toaster: ToasterService,
  ) {}

  protected readonly acForm = new FormGroup({
    amount: new FormControl(0, Validators.required),
  });
  protected readonly inProgress$ = new BehaviorSubject<'save' | 'loading'>(undefined);

  async save() {
    if (this.inProgress$.value) return
    this.acForm.markAllAsTouched()
    this.acForm.updateValueAndValidity()
    if (!this.acForm.valid) return

    this.inProgress$.next('save');
    try {
      await this.OperationsApi.additionalCost(
        this.dialogData.dealId,
        this.acForm.controls.amount.value,
      ).toPromise();
      this.dialogRef.close();
      this.toaster.success('Additional Cost added successfully');
    } catch (error) {
      console.error(error);
      this.toaster.error(error.error.error.message);
    }
    this.inProgress$.next(undefined);
  }

  cancel() {
    this.dialogRef.close();
  }
}
