import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { DealViewRawDeal, DealViewRawInvoices, DealViewRawStatus } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { pickBy } from 'lodash-es'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { patchDealProduct, patchDealProductFailure, patchDealProductSuccess } from 'src/app/store/deal-view.actions'
import { DealFormGroup, DealProductFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'


export interface DealProductOverlayOptions {
  dealViewRaw$: Observable<DeepReadonly<DealViewRawDeal & DealViewRawInvoices & DealViewRawStatus>>
  dealForm: DealFormGroup
  productForm: DealProductFormGroup
}

@Component({
  selector: 'tc-deal-product-overlay',
  templateUrl: './deal-product-overlay.component.html',
  styleUrls: ['./deal-product-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealProductOverlayComponent {

  constructor(
    private actions$: Actions,
    private store: Store,
    private dialogRef: MatDialogRef<DealProductOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DealProductOverlayOptions,
  ) {}

  inProgress$ = new BehaviorSubject(false)
  dealId = this.data.dealForm.value.details.deal.deal_id
  productIndex = this.data.dealForm.controls.products.controls.indexOf(this.data.productForm)
  protected readonly dealViewRaw$ = this.data.dealViewRaw$
  protected readonly invoices$ = this.data.dealViewRaw$.pipe(map(dv => dv.invoices))


  cancel() {
    this.dialogRef.close()
  }

  submit() {
    if (this.inProgress$.value) return
    this.data.productForm.markAllAsTouched()
    this.data.productForm.updateValueAndValidity()
    // NOTE: dirty flag isn't recursive. changing `batches[].lot` would not make `batches` dirty.
    if (!this.data.productForm.controls.batches.dirty && this.data.productForm.controls.batches.controls.find(c => c.dirty)) {
      this.data.productForm.controls.batches.markAsDirty()
    }

    if (!this.data.productForm.valid) return
    this.inProgress$.next(true)
    const deal = this.data.dealForm.value.details.deal
    const dealForm = this.data.dealForm.serialize()
    const patch = pickBy(this.data.productForm.serialize(), (_value, key) =>
      this.data.productForm.controls[key]?.dirty)
    this.dealViewRaw$.pipe(take(1)).subscribe(dv => {
      this.store.dispatch(patchDealProduct({ index: this.productIndex, dv, dealForm, patch }))
      this.actions$.pipe(ofType(patchDealProductSuccess, patchDealProductFailure), take(1)).subscribe((action) => {
        if (action.type === patchDealProductSuccess.type) this.dialogRef.close()
        this.inProgress$.next(false)
      })
    })
  }
}
