import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { InlineEditorModule } from 'src/components/inline-editor/inline-editor.module';
import { TypeSafeMatCellDefModule } from 'src/components/type-safe-mat-cell-def/type-safe-mat-cell-def.module';
import { AdditionalCostDialogModule } from './additional-cost-dialog/additional-cost-dialog.module';
import { DealProductOverlayModule } from './deal-product-overlay/deal-product-overlay.module';
import { DealProductsListComponent } from './deal-products-list.component';

@NgModule({
  declarations: [DealProductsListComponent],
  exports: [DealProductsListComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    InlineEditorModule,
    DealProductOverlayModule,
    TypeSafeMatCellDefModule,
    AdditionalCostDialogModule,
  ],
})
export class DealProductsListModule {}
