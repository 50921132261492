<h2 mat-dialog-title>
  <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;Add Additional Charge
</h2>

<form [formGroup]="acForm" (submit)="save()">
  <mat-dialog-content gdColumns="repeat(2, minmax(0, 1fr))" gdGap="0 15px">
    <mat-form-field>
      <mat-label>Amount</mat-label>
      <input matInput type="number" step="0.01" min="0" [formControl]="acForm.controls.amount" />
    </mat-form-field>
  </mat-dialog-content>

  <mat-progress-bar [mode]="(inProgress$|async) ? 'indeterminate' : undefined" ></mat-progress-bar>
  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button type="submit" color="primary" [disabled]="inProgress$ | async">
      <i class="fa fa-fw" aria-hidden="true"
          [class.fa-save]="(inProgress$ | async) !== 'save'"
          [class.fa-spin]="(inProgress$ | async) === 'save'"
          [class.fa-spinner]="(inProgress$ | async) === 'save'"
          ></i>
      Save
    </button>
  </mat-dialog-actions>
</form>
