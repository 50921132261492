import { Injectable } from '@angular/core';
import { ModalService } from 'src/shared/modal/modal.service';
import {
  AdditionalCostDialogComponent,
  AdditionalCostDialogOptions
} from './additional-cost-dialog.component';

@Injectable()
export class AdditionalCostDialogService {
  constructor(private modal: ModalService) {}

  showAdditionalCost(dealId: string) {
    return this.modal.openDialog<
      AdditionalCostDialogComponent,
      AdditionalCostDialogOptions,
      void
    >(AdditionalCostDialogComponent, {
      dealId,
    });
  }
}
