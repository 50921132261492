import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { DealViewIncompleteComponent } from 'src/shared/deal-view-incomplete/deal-view-incomplete.component'
import { ModalModule } from 'src/shared/modal'
import { DealProductFormModule } from '../deal-product-form/deal-product-form.module'
import { DealProductOverlayComponent } from './deal-product-overlay.component'
import { DealProductOverlayService } from './deal-product-overlay.service'

@NgModule({
  declarations: [DealProductOverlayComponent],
  providers: [DealProductOverlayService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatTooltipModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ModalModule,
    DealProductFormModule,
    DealViewIncompleteComponent,
  ],
})
export class DealProductOverlayModule {}
