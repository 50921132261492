import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ReactiveAsteriskModule } from 'src/components/reactive-asterisk/reactive-asterisk.module';
import { ModalModule } from 'src/shared/modal';
import { AdditionalCostDialogComponent } from './additional-cost-dialog.component';
import { AdditionalCostDialogService } from './additional-cost-dialog.service';

@NgModule({
  declarations: [AdditionalCostDialogComponent],
  providers: [AdditionalCostDialogService],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ModalModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    ReactiveAsteriskModule,
  ],
})
export class AdditionalCostDialogModule {}
