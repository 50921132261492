import { Injectable } from '@angular/core'
import { DealViewRawDeal, DealViewRawInvoices, DealViewRawStatus } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { Observable } from 'rxjs'
import { DealFormGroup, DealProductFormGroup } from 'src/pages/admin/trading/deal-form/deal-form-page/deal-form.schema'
import { ModalService } from 'src/shared/modal/modal.service'
import { DealProductOverlayComponent, DealProductOverlayOptions } from './deal-product-overlay.component'

@Injectable()
export class DealProductOverlayService {
  constructor(private modal: ModalService) { }

  showProductDetails(dealViewRaw$: Observable<DeepReadonly<DealViewRawDeal & DealViewRawInvoices & DealViewRawStatus>>, dealForm: DealFormGroup, productForm: DealProductFormGroup) {
    return this.modal.openLargeSide<DealProductOverlayComponent, DealProductOverlayOptions>(DealProductOverlayComponent, {
      dealViewRaw$, dealForm, productForm,
    })
  }
}
